<template>
  <div class="list">
    <div class="globle_border" v-loading="loading">
      <div class="search">
        <!-- <el-cascader v-model="value" :options="options" collapse-tags :props="{ expandTrigger: 'click', label: 'categoryName', value: 'categoryId', children: 'children', checkStrictly: false, emitPath: false , multiple: true}" @change="onSearch" clearable placeholder="搜索商品类目(二级)"></el-cascader> -->
        <el-cascader v-model="value" :options="options" collapse-tags :props="{ expandTrigger: 'click', label: 'categoryName', value: 'categoryId', children: 'children', checkStrictly: false, emitPath: false, multiple: true }" @change="onSearch" clearable placeholder="搜索商品类目(二级)"></el-cascader>
        <el-input v-model="supplierName" placeholder="请搜索供应商名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-input v-model="productName" placeholder="请搜索商品名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-input v-model="barCode" placeholder="请搜索商品条形码" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-input v-model="factoryName" placeholder="请搜索厂家" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-button type="primary" @click="handleDelALL" :disabled="multipleSelection.length == 0">批量删除</el-button>
      </div>
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540" :row-key="row => row.id" @selection-change="handleSelectionChange" ref="multipleTable">
          <el-table-column type="selection" width="55" :selectable="row => row.productBox > 0" :reserve-selection="true"></el-table-column>
          <el-table-column prop="number" label="序号" width="80">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column min-width="100" prop="supplierName" label="供应商名称" show-overflow-tooltip></el-table-column>
          <el-table-column prop="barCode" label="商品条形码" show-overflow-tooltip min-width="140"></el-table-column>
          <el-table-column prop="productName" label="商品名称" show-overflow-tooltip></el-table-column>
          <el-table-column prop="specs" label="规格" show-overflow-tooltip></el-table-column>
          <el-table-column prop="categoryName" label="商品类目" show-overflow-tooltip></el-table-column>
          <el-table-column prop="enterBox" label="入箱数"></el-table-column>
          <el-table-column prop="productBox" label="商品箱数" align="center">
            <template slot-scope="scope">
              <el-input v-model.number="scope.row.productBox" :min="0" @change="handleIncrease(scope.row)" size="mini"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="quantity" label="商品数量"></el-table-column>
          <el-table-column prop="factoryName" label="厂家" show-overflow-tooltip></el-table-column>
          <el-table-column prop="brand" label="品牌" show-overflow-tooltip></el-table-column>
          <el-table-column min-width="120" prop="expirationDay" label="保质期（天）"></el-table-column>
          <el-table-column min-width="120" prop="supplyPrice" label="供货价（元）"></el-table-column>
          <el-table-column label="操作" header-align="center" align="center" fixed="right">
            <template slot-scope="scope">
              <!-- <el-button class="btn" type="primary" size="small" plain @click="handleIncrease(scope.row,1)">增加</el-button> -->
              <!-- <el-button class="btn" type="primary" size="small" plain @click="handleIncrease(scope.row,2)" :disabled="!scope.row.quantity>0">减少</el-button> -->
              <el-button class="btn" type="danger" size="small" plain @click="handleDel(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>
    <div class="amount">
      合计：
      <span class="red">
        {{ amount }} 元
        <span class="gray">共 {{ numberBox }} 箱商品</span>
      </span>
      <el-button class="btns" type="danger" @click="handleSettlement" :disabled="multipleSelection.length == 0">提交</el-button>
    </div>
    <el-dialog title="提交" :visible.sync="dialogVisible" :before-close="onCancel">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="收货人" prop="receiverUser">
              <el-input v-model="ruleForm.receiverUser" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="电话" prop="receiverPhone">
              <el-input v-model="ruleForm.receiverPhone" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="地址" prop="address">
              <el-input v-model="ruleForm.address" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="submitLoding">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ids: [],
      amount: 0,
      quantity: 1,
      number: 1,
      dialogVisible: false,
      submitLoding: false,
      ruleForm: {
        receiverUser: "",
        address: "",
        receiverPhone: "",
      },
      rules: {
        address: [{ required: true, message: "地址不能为空", trigger: "submit" }],
        receiverUser: [{ required: true, message: "收货人不能为空", trigger: "submit" }],
        receiverPhone: [
          { required: true, message: "数量不能少于", trigger: "submit" },
          {
            validator: function (rule, value, callback) {
              if (/^(13[0-9]|14[0-9]|15[0-9]|16[6]|18[0-9]|19[6,9]|17[0-9])\d{8}$/i.test(value) == false) {
                callback(new Error("请输入手机号"))
              } else {
                //校验通过
                callback()
              }
            },
            trigger: "blur",
          },
        ],
      },
      loading: false,
      factoryName: "",
      productName: "",
      barCode: "",
      supplierName: "",
      tableData: [],
      multipleSelection: [], // 选中数据
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 30], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      value: [],
      options: [{}],
      shopId: "",
      data: {},
      numberBox: 0, //总数量
    }
  },
  created() {
    this.shopId = this.$store.state.userInfo.shopId
    this.getSelect_purchase_cart()
    // this.getCategoryLIst()
    this.getCategoryInfoList()
    this.handleShop()
  },

  methods: {
    // 批量删除
    handleDelALL() {
      // this.multipleSelection 数组中取出id返回数组
      let ids = this.multipleSelection.map(item => item.id)
      this.$confirm(`是否确认删除?`, "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios
            .post(this.$api.delete_purchase_cart, ids)
            .then(res => {
              if (res.data.code == 100) {
                this.tableData = []
                this.$message.success(res.data.desc)
                this.getSelect_purchase_cart()
                this.$refs.multipleTable.clearSelection()
                // this.amount = 0
                // console.log('删除', this.multipleSelection);
              }
            })
            .catch(() => { })
        })
        .catch(() => { })
    },
    // 获取商超联系方式
    handleShop() {
      this.$axios
        .get(this.$api.purchase_cart_shop_info, {
          params: {
            shopId: this.shopId,
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            this.data = res.data.result
          }
        })
        .catch(() => { })
    },
    // 商超列表
    getSelect_purchase_cart() {
      this.loading = true
      this.$axios
        .post(this.$api.select_purchase_cart, {
          categoryIdList: this.value, //商品分类id
          factoryName: this.factoryName, //厂家
          productName: this.productName, //商品名字
          barCode: this.barCode, //商品Code
          supplierName: this.supplierName, //供应商名字
          shopId: this.shopId, //商超
          page: this.currentPage,
          pageSize: this.pageSize,
          type: 1, //区分进货套餐
        })
        .then(res => {
          this.tableData = res.data.result.list || []
          this.totalItemsCount = res.data.result.totalCount
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 分类
    // getCategoryLIst() {
    //   this.$axios.get(this.$api.select_purchase_cart_category, {
    //     params: {
    //       shopId: this.shopId
    //     },
    //   }).then((res) => {
    //     this.options = res.data.result
    //     this.options.map((item) => {
    //       if (item.children.length == 0) {
    //         item.children = null
    //       }
    //     })
    //   }).catch(() => {
    //   });
    // },
    getCategoryInfoList() {
      this.$axios
        .get(this.$api.getCategoryInfoList, {
          params: {
            auditStatus: "1",
            page: 1,
            pageSize: 9999,
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            this.options = res.data.result.list
            this.options.map(item => {
              if (item.children.length == 0) {
                item.children = null
              }
            })
          }
          // this.loading = false
        })
    },
    // 选中
    handleSelectionChange(val) {
      this.multipleSelection = val.map(t => ({
        quantity: t.quantity,
        supplyPrice: t.supplyPrice,
        productId: t.productId,
        id: t.id,
        amount: t.quantity * t.supplyPrice,
        productBox: t.productBox,
      }))
      this.handleAmount()
      console.log('选中', this.multipleSelection);
    },

    handleAmount() {
      // console.log('合计', this.multipleSelection);
      const total = this.multipleSelection.reduce((accumulator, item) => accumulator + item.amount, 0)
      const numberBox = this.multipleSelection.reduce((accumulator, item) => accumulator + item.productBox, 0)
      // console.log(numberBox);
      this.numberBox = numberBox
      // console.log('2', numberBox);
      this.amount = parseFloat(total.toFixed(2))
    },
    onSelectable(row) {
      return row.quantity > 0
    },
    // 修改购物车
    handleIncrease(row) {
      if (row.productBox == 0) {
        this.$refs.multipleTable.toggleRowSelection(row, false)
      }
      let boolean = new RegExp("^[1-9][0-9]*$").test(row.productBox)
      if (!boolean) {
        this.$message.warning("请输入正整数")
        row.productBox = 0
        return
      }
      // console.log('row', row);
      this.$axios.post(this.$api.update_purchase_cart, {
        id: row.id,
        quantity: row.quantity,
        productBox: row.productBox,
        enterBox: row.enterBox,
        // productId: row.productId,
        // shopId: this.shopId,
        // supplierId: row.supplierId,
      })
      .then(res => {
        if (res.data.code == 100) {
          // this.$message.success('操作成功');
          // this.getSelect_purchase_cart()
          row.quantity = row.productBox * row.enterBox

          this.$refs.multipleTable.toggleRowSelection(row, false)
          }
        })
        .catch(() => { })
      // console.log('1', this.multipleSelection);
      // this.handleAmount()
      this.multipleSelection.forEach(t => {
        if (t.productId == row.productId) {
          t.quantity = row.quantity
          t.amount = row.quantity * row.supplyPrice
        }
      })
    },

    // 去结算
    handleSettlement() {
      this.dialogVisible = true
      this.ids = this.multipleSelection.map(t => t.id)
      // console.log(this.data);
      if (this.data) {
        this.ruleForm.receiverUser = this.data.contactMan
        this.ruleForm.address = this.data.address
        this.ruleForm.receiverPhone = this.data.contactTel
      }
    },
    // 提交表单
    submitForm(formName) {
      const { address, receiverPhone, receiverUser } = this.ruleForm
      let param = {
        address: address,
        receiverPhone: receiverPhone,
        receiverUser: receiverUser,
        shopId: this.shopId,
        cartIdList: this.ids,
        paymentPrice: this.amount,
      }
      // console.log(param);
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$axios.post(this.$api.shop_save_purchase_order, param).then(res => {
            if (res.data.code == 100) {
              this.$message.success("结算成功")
              this.getSelect_purchase_cart()
              this.dialogVisible = false
              // this.resetForm();
              this.amount = 0
              this.$refs.multipleTable.clearSelection()
            }
          })
        } else {
          this.$message.error("结算失败")
          return false
        }
      })
    },
    // 关闭对话框
    onCancel() {
      this.dialogVisible = false
    },
    // 删除
    handleDel(row) {
      // console.log(row);
      this.$confirm(`是否确认删除?`, "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios
            .post(this.$api.delete_purchase_cart, [row.id])
            .then(res => {
              if (res.data.code == 100) {
                this.tableData = []
                this.$message.success(res.data.desc)
                this.getSelect_purchase_cart()
                this.$refs.multipleTable.toggleRowSelection(row, false)
                this.multipleSelection = JSON.parse(JSON.stringify(this.multipleSelection.filter(t => t.id != row.id)))
                this.handleAmount()
                // console.log('删除', this.multipleSelection);
              }
            })
            .catch(() => { })
        })
        .catch(() => { })
    },
    // 搜索
    onSearch() {
      // console.log(this.value);
      this.tableData = []
      this.currentPage = 1
      this.getSelect_purchase_cart()
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.tableData = []
      this.pageSize = e
      this.getSelect_purchase_cart()
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = []
      this.currentPage = e
      this.getSelect_purchase_cart()
    },
  },
}
</script>

<style scoped lang="scss">
.list {
  .btns {
    float: right;
    margin-right: 36px;
  }

  .amount {
    margin-left: 36px;
    font-size: 24px;
  }

  .red {
    color: red;
  }

  .gray {
    margin-left: 10px;
    color: gray;
    font-size: 16px;
  }

  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    // height: 44px;

    .el-cascader {
      margin-left: 20px;
      margin-bottom: 20px;
    }

    .el-input {
      margin-left: 20px;
      margin-bottom: 20px;
      width: 160px;
    }

    .el-button {
      margin-bottom: 20px;
      margin-left: 20px;
    }
  }

  .show_img {
    color: #069bbc;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}

.el-input-number {
  width: 255px;
}
</style>
